import to from '../../plugins/to';
import api from '../../plugins/api';

const SET_IMAGES = 'SET_IMAGES';

export const state = {
    images: [],
};

export const actions = {
    async fetchImages({ commit }) {
        const [err, res] = await to(api.get('/temporary-images'));

        if (!err) {
            const { data: { data: images } } = res;

            commit(SET_IMAGES, { images });
        } else {
            console.error(err);
        }
    },
};

export const mutations = {
    [SET_IMAGES](s, { images }) {
        s.images = images;
    },
};
