import axios from 'axios';

const instance = axios.create({
    baseURL: `${window.location.origin}/api/v1/`,
    withCredentials: true,
});

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default instance;
