import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import store from './store';

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    store.dispatch('loading/startLoading');

    if (to.name !== 'auth.login' && !store.getters['auth/authenticated']) {
        return next({ name: 'auth.login' });
    }

    return next();
});

router.afterEach(async () => {
    await store.dispatch('loading/finishLoading');

    if (store.getters['screen/size'] < 3 && store.getters['dashboard/sidebarOpen']) {
        store.dispatch('dashboard/closeSidebar');
    }
});

export default router;
