import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

export default new Echo({
    broadcaster: 'pusher',
    key: '8295310d725a5c6765a6',
    cluster: 'us2',
    forceTLS: true,
});
