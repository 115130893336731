import to from '../../plugins/to';
import api from '../../plugins/api';

const SET_CATEGORIES = 'SET_CATEGORIES';

export const state = {
    categories: [],
};

export const actions = {
    async deleteCategory({ dispatch }, { category }) {
        const [err] = await to(api.delete(`/categories/${category.id}`));

        if (!err) {
            await dispatch('fetchCategories');
        } else {
            console.error(err);
        }
    },
    async fetchCategories({ commit }) {
        const [err, res] = await to(api.get('/categories'));

        if (!err) {
            const { data: { data: categories } } = res;

            commit(SET_CATEGORIES, { categories });
        } else {
            console.error(err);
        }
    },
};

export const mutations = {
    [SET_CATEGORIES](s, { categories }) {
        s.categories = categories;
    },
};
