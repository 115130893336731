const SET_SCREEN = 'SET_SCREEN';

export const state = {
    screen: {},
};

export const actions = {
    setScreen({ commit }, { screen }) {
        commit(SET_SCREEN, { screen });
    },
};

export const mutations = {
    [SET_SCREEN](s, { screen }) {
        s.screen = screen;
    },
};

export const getters = {
    size: (s) => s.screen.size,
};
