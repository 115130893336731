import auth from './auth';
import albums from './albums';
import images from './images';
import categories from './categories';
import errors from './errors';

const EmptyRouterView = () => import('../components/Presentation/EmptyRouterView.vue');
const Home = () => import('../pages/Home.vue');
const Settings = () => import('../pages/Settings.vue');
const ProfileSettings = () => import('../pages/profile/Settings.vue');
const Dashboard = () => import('../layouts/Dashboard/Dashboard.vue');
const TemporaryImages = () => import('../pages/temporary-images/Index.vue');

export default [
    ...auth,
    {
        path: '/',
        component: EmptyRouterView,
        meta: {
            breadcrumb: 'Home',
        },
        children: [
            {
                path: '',
                component: Dashboard,
                name: 'dashboard',
                children: [
                    ...albums,
                    ...categories,
                    ...images,
                    {
                        path: '',
                        component: Home,
                        name: 'dashboard.home',
                        meta: {
                            breadcrumb: 'Home',
                        },
                    },
                    {
                        path: '/settings',
                        component: Settings,
                        name: 'dashboard.settings',
                        meta: {
                            breadcrumb: 'Settings',
                        },
                    },
                    {
                        path: '/temporary-images',
                        component: TemporaryImages,
                        name: 'dashboard.temporaryImages',
                        meta: {
                            breadcrumb: 'Temporary Images',
                        },
                    },
                    {
                        path: '/profile',
                        component: ProfileSettings,
                        name: 'dashboard.profile',
                        meta: {
                            breadcrumb: 'Profile Settings',
                        },
                    },
                    ...errors,
                ],
            },
        ],
    },
];
