const Error404 = () => import('../pages/errors/404.vue');

export default [
    {
        name: 'errors.404',
        path: '/not-found',
        component: Error404,
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: Error404,
    },
];
