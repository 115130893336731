import axios from 'axios';
import to from '../../plugins/to';
import api from '../../plugins/api';
import router from '../../router';

const SET_USER = 'SET_USER';
const SET_AUTHENTICATED = 'SET_AUTHENTICATED';

export const state = {
    user: {},
    authenticated: false,
};

export const getters = {
    authenticated: (s) => s.authenticated === true,
};

export const actions = {
    async login({ dispatch, state: s }, { email, password }) {
        await axios.get('/sanctum/csrf-cookie');

        const [err, res] = await to(api.post('/login', {
            email,
            password,
        }));

        if (err) {
            const {
                response: {
                    data: { errors },
                },
            } = err;

            return { errors };
        }

        await dispatch('fetchUser');

        if (s.authenticated) {
            await router.push({ name: 'dashboard.home' });
        }

        return res;
    },
    async fetchUser({ commit }) {
        try {
            const [err, res] = await to(api.get('/user'));

            if (!err) {
                const { data: { data: user } } = res;

                commit(SET_USER, { user });
                commit(SET_AUTHENTICATED, true);
            } else {
                commit(SET_USER, { user: null });
                commit(SET_AUTHENTICATED, false);

                await router.push({ name: 'auth.login' });

                console.error(err); // eslint-disable-line
            }
        } catch (e) {
            console.error(e); // eslint-disable-line
        }
    },
    async logout({ commit }) {
        const [err] = await to(api.post('/logout'));

        if (!err) {
            commit(SET_AUTHENTICATED, false);
            commit(SET_USER, { user: null });

            await router.push({ name: 'auth.login' });
        } else {
            console.error(err); // eslint-disable-line
        }
    },
};

export const mutations = {
    [SET_USER](s, { user }) {
        s.user = user;
    },
    [SET_AUTHENTICATED](s, authenticated) {
        s.authenticated = authenticated;
    },
};
