import 'es6-promise/auto';
import { createStore } from 'vuex';

const requireContext = require.context('./modules', false, /.*\.js$/);

const modules = requireContext.keys()
    .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
    .reduce((m, [name, mod]) => {
        const module = { ...mod };

        if (module.namespaced === undefined) {
            module.namespaced = true;
        }

        return { ...m, [name]: module };
    }, {});

const store = createStore({
    modules,
});

export default store;
