const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

export const state = {
    breadcrumbs: [],
};

export const actions = {
    setBreadcrumbs({ commit }, routes) {
        const breadcrumbs = routes.map((match) => {
            const crumb = {
                name: match.meta.breadcrumb,
                to: null,
            };

            if (match.to) {
                crumb.to = match.to;
            } else if (match.path) {
                crumb.to = match.path;
            }

            return crumb;
        }).filter((crumb) => crumb.to !== null && crumb.name);

        commit(SET_BREADCRUMBS, { breadcrumbs });
    },
};

export const mutations = {
    [SET_BREADCRUMBS](s, { breadcrumbs }) {
        s.breadcrumbs = breadcrumbs;
    },
};
