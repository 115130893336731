const Index = () => import('../pages/categories/Index.vue');
const Create = () => import('../pages/categories/Create.vue');

export default [
    {
        path: '/categories',
        name: 'dashboard.categories',
        component: Index,
        meta: {
            breadcrumb: 'Categories',
        },
    },
    {
        path: '/categories/create',
        name: 'dashboard.categories.create',
        component: Create,
        meta: {
            breadcrumb: 'New Category',
        },
    },
];
