import to from '../../plugins/to';
import api from '../../plugins/api';

const SET_RECENT_ACTIVITY_ALBUMS = 'SET_RECENT_ACTIVITY_ALBUMS';

export const state = {
    recentActivityAlbums: [],
};

export const actions = {
    async fetchRecentActivityAlbums({ commit }) {
        const [err, res] = await to(api.get('/albums', {
            params: {
                orderBy: 'updated_at,desc',
                limit: 4,
            },
        }));

        if (!err) {
            const { data: { data: albums } } = res;

            commit(SET_RECENT_ACTIVITY_ALBUMS, { albums });
        } else {
            console.error(err);
        }
    },
};

export const mutations = {
    [SET_RECENT_ACTIVITY_ALBUMS](s, { albums }) {
        s.recentActivityAlbums = albums;
    },
};
