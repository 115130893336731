<template>
   <div class="app">
       <router-view />
   </div>
</template>

<script>
import { _html, _win } from '@weepower/core/scripts/core/variables';
import { mapActions, mapState } from 'vuex';
import $screen from '@weepower/core/scripts/wee-screen';
import echo from './plugins/echo';

export default {
    name: 'App',
    created() {
        const { getComputedStyle } = _win;

        const theme = getComputedStyle(_html)
            .getPropertyValue('--mode')
            .trim();

        this.setTheme({ theme });
    },
    mounted() {
        $screen.map([
            {
                each: true,
                callback: (screen) => {
                    this.setScreen({ screen });
                },
            },
        ]);

        if (this.user) {
            echo.private(`user.${this.user.id}.toast`)
                .listen('ToastEvent', ({ message, config }) => {
                    this.$toast.show(message, config);
                });
        }
    },
    methods: {
        ...mapActions('dashboard', ['setTheme']),
        ...mapActions('screen', [
            'setScreen',
        ]),
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    data: () => ({
        email: '',
        password: '',
    }),
};
</script>

<style lang="scss">
@import '@/assets/styles/global.scss';

.app {
    overflow-x: hidden;
}
</style>
