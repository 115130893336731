const Index = () => import('../pages/albums/Index.vue');
const Create = () => import('../pages/albums/Create.vue');
const Edit = () => import('../pages/albums/Edit.vue');
const Show = () => import('../pages/albums/Show.vue');

export default [
    {
        path: '/albums',
        component: Index,
        name: 'dashboard.albums',
        meta: {
            breadcrumb: 'Albums',
        },
    },
    {
        path: '/albums/:albumId',
        component: Show,
        name: 'dashboard.albums.show',
        meta: {
            breadcrumb: 'Show',
        },
    },
    {
        path: '/albums/:albumId/edit',
        component: Edit,
        name: 'dashboard.albums.edit',
        meta: {
            breadcrumb: 'Edit',
        },
    },
    {
        path: '/albums/create',
        component: Create,
        name: 'dashboard.albums.create',
        meta: {
            breadcrumb: 'Create',
        },
    },
];
