const Show = () => import('../pages/albums/images/Show.vue');

export default [
    {
        path: '/albums/:albumId/images/:imageId',
        component: Show,
        name: 'dashboard.albums.image.show',
        meta: {
            breadcrumb: 'Show',
        },
    },
];
