const SET_LOADING = 'SET_LOADING';

export const state = {
    loading: false,
};

export const actions = {
    finishLoading({ commit }) {
        commit(SET_LOADING, { loading: false });
    },
    startLoading({ commit }) {
        commit(SET_LOADING, { loading: true });
    },
};

export const mutations = {
    [SET_LOADING](s, { loading }) {
        s.loading = loading;
    },
};
