import api from '../../plugins/api';
import to from '../../plugins/to';

const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
const SET_NAVIGATION = 'SET_NAVIGATION';
const SET_THEME = 'SET_THEME';

export const state = {
    sidebarOpen: false,
    navigation: [],
    theme: 'light',
};

export const mutations = {
    [TOGGLE_SIDEBAR](s) {
        s.sidebarOpen = !s.sidebarOpen;
    },
    [CLOSE_SIDEBAR](s) {
        s.sidebarOpen = false;
    },
    [SET_NAVIGATION](s, { navigation }) {
        s.navigation = navigation;
    },
    [SET_THEME](s, { theme }) {
        s.theme = theme;
    },
};

export const actions = {
    toggleSidebar({ commit }) {
        commit(TOGGLE_SIDEBAR);
    },
    closeSidebar({ commit }) {
        commit(CLOSE_SIDEBAR);
    },
    async fetchNavigation({ commit }) {
        const [err, res] = await to(api.get('/navigation'));

        if (!err) {
            const { data: navigation } = res;

            commit(SET_NAVIGATION, { navigation });
        }
    },
    setTheme({ commit }, { theme }) {
        commit(SET_THEME, { theme });

        document.querySelector('html')
            .setAttribute('data-theme', theme);
    },
    toggleTheme({ state: s, dispatch }) {
        const theme = s.theme === 'light'
            ? 'dark'
            : 'light';

        dispatch('setTheme', { theme });
    },
};

export const getters = {
    sidebarOpen: (s) => s.sidebarOpen === true,
};
