import to from '../../plugins/to';
import api from '../../plugins/api';
import router from '../../router';

const SET_IMAGE = 'SET_IMAGE';
const DELETE_IMAGE = 'DELETE_IMAGE';

export const state = {
    image: {},
};

export const actions = {
    async fetchImage({ commit }, { albumId, imageId }) {
        const [err, res] = await to(api.get(`/albums/${albumId}/images/${imageId}`));

        if (!err) {
            const { data: { data: image } } = res;

            commit(SET_IMAGE, { image });
        } else {
            await router.push({ name: 'errors.404' });
            console.error(err);
        }
    },

    async deleteImage(_, { albumId, imageId }) {
        const [err, res] = await to(api.delete(`/albums/${albumId}/images/${imageId}`));

        return err || res;
    },
};

export const mutations = {
    [SET_IMAGE](s, { image }) {
        s.image = image;
    },
    [DELETE_IMAGE](s) {
        s.image = {};
    },
};
