import to from '../../plugins/to';
import api from '../../plugins/api';
import router from '../../router';

const SET_ALBUM = 'SET_ALBUM';
const UPDATE_THUMBNAIL = 'UPDATE_THUMBNAIL';
const ADD_IMAGE = 'ADD_IMAGE';

export const state = {
    album: {},
};

export const actions = {
    async deleteAlbum(_, { albumId }) {
        const [err, res] = await to(api.delete(`/albums/${albumId}`));

        return err || res;
    },
    addImage({ commit }, { image }) {
        commit(ADD_IMAGE, { image });
    },
    updateThumbnail({ commit }, { image }) {
        commit(UPDATE_THUMBNAIL, { image });
    },
    async fetchAlbum({ commit }, { id }) {
        const [err, res] = await to(api.get(`/albums/${id}`));

        if (!err) {
            const { data: { data: album } } = res;

            // TODO: Move this to a backend property
            album.hasMap = album.images.filter((image) => image.exif && image.exif.gps.length).length > 0;

            commit(SET_ALBUM, { album });
        } else {
            await router.push({ name: 'errors.404' });
            console.error(err);
        }
    },
};

export const mutations = {
    [SET_ALBUM](s, { album }) {
        s.album = album;
    },
    [UPDATE_THUMBNAIL](s, { image }) {
        s.album.images = s.album.images.map((img) => {
            if (img.id === image.id) {
                img.thumbnail = image.thumbnail;
            }

            return img;
        });
    },
    [ADD_IMAGE](s, { image }) {
        s.album.images.push(image);
    },
};
