const SET_UPLOADING = 'SET_UPLOADING';

export const state = {
    uploading: false,
};

export const actions = {
    async setUploading({ commit }, uploading) {
        commit(SET_UPLOADING, uploading);
    },
};

export const mutations = {
    [SET_UPLOADING](s, uploading) {
        s.uploading = uploading;
    },
};
